body {
  overflow-x: hidden;
}

.app-stores-page {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.app-stores-page h1 {
  font-size: 2rem;
}

.app-stores-page p {
  font-size: 1.2rem;
}

.app-store-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.app-store-buttons img {
  width: 200px;
  height: 70px;
  margin: 0 0.5rem;
}